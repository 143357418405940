// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admins-index-js": () => import("./../../../src/pages/admins/index.js" /* webpackChunkName: "component---src-pages-admins-index-js" */),
  "component---src-pages-admins-menus-edit-js": () => import("./../../../src/pages/admins/menus/edit.js" /* webpackChunkName: "component---src-pages-admins-menus-edit-js" */),
  "component---src-pages-admins-menus-index-js": () => import("./../../../src/pages/admins/menus/index.js" /* webpackChunkName: "component---src-pages-admins-menus-index-js" */),
  "component---src-pages-admins-menus-new-js": () => import("./../../../src/pages/admins/menus/new.js" /* webpackChunkName: "component---src-pages-admins-menus-new-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menus-js": () => import("./../../../src/pages/menus.js" /* webpackChunkName: "component---src-pages-menus-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-reservation-js": () => import("./../../../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */)
}

